import tools from "../../../extends/tools";
export default {
    dataInit: null,
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    showData: {},
    saveData: {
        number: '',
        type: '',
        class: '',
        total: 0,
        total_copy: 0,
        business_date: tools.now(),
        remarks: '',
        enclosure: [],
        details: '',
        amount: 0,
        amount_copy: 0,
        settlement_method: ''
    },
    showData: {}
}